<template>
  <transition name="modal">
    <div class="modal-mask" style="overflow-y: scroll;" @click="hide()">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="send-custom-offer-main">
          <div class="close-div">
            <img  @click="hide()" style="opacity: 1; cursor:pointer;width: 16px; height: 16px;"
                  src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
          </div>
          <p class="custom-offer-header">Şikayet Et</p>
          <div style="width: 100%">
            <label class="custom-offer-label" style="margin-top: 34px;">Şikayet nedenini birkaç cümleyle anlatır mısın? Karşı taraf, şikayet talebinden haberdar olmayacak. 🤫</label>
            <textarea v-model="textArea" style="width: calc(100% - 22px); height: 100px;" ></textarea>
          </div>
          <div class="seperator"></div>
          <button @click="sendComplaint()" class="super-button" style="">Gönder</button>
        </div>
      </div>
    </div>
  </transition>


</template>

<script>

  export default {
    name: "src-pages-modals-complaint-v1_mobile",
    data () {
      return {
        type: null,
        externalId: null,
        reportTypeId: null,
        textArea: ""
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },


      sendComplaint() {
        this.api.general.report(this.type, this.externalId, this.reportTypeId, this.textArea)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.hide();
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },

    computed: {
      getModalInfo() {
        let info = this.$store.state.activeModal.info;

        if (info) {
          this.type = info.type;
          this.externalId = info.externalId;
          this.reportTypeId = info.reportTypeId;
        }
        return true;
      }
    }


  }

</script>

<style scoped lang="scss">
  .modal-container {
    margin-top: 60px;
    padding: 0;
    font-family: 'sofia-pro';
    overflow-y: scroll;
    width: calc(100vw - 30px) !important;
    max-width: 450px !important;
    height: calc(100vh - 50px) !important;
    max-height: 415px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    position: relative;
  }

  .send-custom-offer-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    padding: 50px 15px 20px 15px;
  }

  .custom-offer-header {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
  }

  .custom-offer-label {
    font-size: 14px;
    text-align: left;
    color: #2d3640;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .price-duration {
    display: flex;
  }

  .seperator {
    width: 100%;
    height: 2px;
    border-bottom: solid 1px #dfe4ec;
    margin-top: 30px;
    margin-bottom: 18px;
  }
  .super-button {
    min-width: 170px;
    height: 50px;
    border-radius: 2px;
    background-color: #FD4056;
    &:hover {
      background-color: #e94258;
    }
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

</style>
